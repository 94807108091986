import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSurveyService from '../../../app/services/hooks/useSurveyService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { defaultSurvey, Survey } from '../../../app/models/Survey';
import { Section } from '../../../app/models/Section';
import { QuestionDataForm } from '../../../app/models/Question';
import useUserResponseService from '../../../app/services/hooks/useUserResponseService';
import RespondSurvey from '../subpages/RespondSurvey';
import LazyLoading from '../../../components/LazyLoading';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';

type RouterParams = {
    id: string;
};

const RepondSurveyContainer = () => {

    const { id } = useParams<RouterParams>();
    const queryParams = new URLSearchParams(location.search);
    const isContinuation = queryParams.get('user_response') === 'true';
    const calendarDate = queryParams.get('date');

    const { showLoading, hideLoading } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();

    const { getSurveyById, fetchingGetSurvey } = useSurveyService();
    const { getInProgressUserResponsesById, storeUserResponse, fetchingGetInProgressUserResponsesById } = useUserResponseService();

    const [ survey, setSurvey ] = useState<Survey>(defaultSurvey);
    const [ sections, setSections ] = useState<Section[]>([]);
    const [ allQuestions, setAllQuestions ] = useState<QuestionDataForm[]>([]);

    const [ checklistQuestions, setChecklistQuestions ] = useState<QuestionDataForm[]>([]);
    const [ historyQuestions, setHistoryQuestions ] = useState<QuestionDataForm[]>([]);

    const [ nextSectionId, setNextSectionId ] = useState<number>();
    const [ hasNextSection, setHasNextSection ] = useState<boolean>(false);
    const [ userResponse, setUserResponse ] = useState<number>();

    const [ currentSectionId, setCurrentSectionId ] = useState<number>();
    const [ currentSectionName, setCurrentSectionName ] = useState<string>('')

    const serviceEvents = {
        onSuccess: (response: ServiceResponse) => {
            setSurvey(response.data.survey);
            setSections(response.data.survey.sections);
            setAllQuestions(response.data.questions)
            if(isContinuation) setCurrentSectionId(response.data.next_section)
        },
        onError: (response: ServiceResponse) => {
            toast.error(response.message, {
                autoClose: 2000
            });
        }
    }

    const setSectionStates = (section: Section) => {
        if(section.questions){
            setChecklistQuestions(section.questions);
            setHistoryQuestions([section.questions[0]]);
        }

        if(section.title) setCurrentSectionName(section.title);
        if(section.next_section_id) setNextSectionId(section.next_section_id);
    }

    useEffect(() => {
        if (sections?.length > 0) {

            if(isContinuation){
                const currentSection = sections.find(scn => scn.id === currentSectionId);
                if(currentSection) setSectionStates(currentSection);
            } else{
                setSectionStates(sections[0]);
            }
        }
    }, [sections]);

    useEffect(() => {
        if (isContinuation){ 
            getInProgressUserResponsesById(Number(id), serviceEvents);
            setUserResponse(Number(id));
        } else {
            getSurveyById(Number(id), serviceEvents);
        }
        
    }, [id]);
    
    const store = (userResponse:any) => {
        storeUserResponse(userResponse, {
            onSuccess: (response: ServiceResponse) => {
                
                toast.success(response.message, {
                    autoClose: 2500
                });

                setUserResponse(response.data.user_response_id);

                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                if (hasNextSection) {
                    navigationPage('/surveys/' + response.data.user_response_id + '/respond?user_response=true&date=' + calendarDate);
                    window.location.reload();
                }else  {
                    console.log(hasNextSection)
                }

            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
        });
    };

    if (fetchingGetSurvey || fetchingGetInProgressUserResponsesById) return <LazyLoading />

    return (
        <div>
            <RespondSurvey 
                survey={survey}
                sections={sections}
                allQuestions={allQuestions}
                storeUserResponse={store}
                nextSectionId={nextSectionId}
                setNextSectionId={setNextSectionId}
                hasNextSection={hasNextSection}
                setHasNextSection={setHasNextSection}
                userResponseId={userResponse} 
                checklistQuestions={checklistQuestions} 
                setChecklistQuestions={setChecklistQuestions} 
                historyQuestions={historyQuestions} 
                setHistoryQuestions={setHistoryQuestions} 
                currentSectionName={currentSectionName}   
                calendarDate={calendarDate!}      
            />
        </div>
    )
}

export default RepondSurveyContainer


