import React, { useEffect, useState, useContext } from 'react';
import { Sidebar, Menu, MenuItem, MenuItemStyles } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import useSidebarItemsService from '../../../app/services/hooks/useSidebarItemsService';
import LazyLoading from '../../../components/LazyLoading';
import { AuthContext } from '../../../contexts/AuthContext';
import { AppContext } from '../../../contexts/AppContext';
import { getMenuItemStyles, hexToRgba, themes } from './Sidebar/utils/getTheme';
import MenuTop from './MenuTop';
import { DefaultIcon } from './Sidebar/icons/DefaultIcon';
import SearchBar from './SideBarSearch';
import { SidebarItem } from '../../../app/models/SidebarItem';
import SubMenuComponent from './Sidebar/components/SubMenuComponent';

type Theme = 'light' | 'dark';

const SideBarPro = () => {
  const location = useLocation();
  const { showingMainMenu, showMainMenu, hideMainMenu } = useContext(AppContext);
  const { updateAuth, auth } = useContext(AuthContext);

  const [collapsed, setCollapsed] = useState(false);
  const [menuItemStyles, setMenuItemStyles] = useState<MenuItemStyles | undefined>(undefined);
  const [broken, setBroken] = useState(false);
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState<Theme>('dark');

  const { getSidebarItems } = useSidebarItemsService();
  const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([]);
  const [filteredSidebarItems, setFilteredSidebarItems] = useState<SidebarItem[]>([]);
  const [openKeys, setOpenKeys] = useState<number[]>([]);
  const [initialOpenKeys, setInitialOpenKeys] = useState<number[]>([]); // Nuevo estado
  const [showSearch, setShowSearch] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);

  /**
   * Helper function to get all paths recursively
   * for a menu item and its children.
   */
  const getAllPaths = (item: SidebarItem): string[] => {
    let paths: string[] = [];
    if (item.url) {
      paths.push(item.url);
    }
    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => {
        paths = paths.concat(getAllPaths(child));
      });
    }
    return paths;
  };

  /**
   * Process sidebar items to include all paths
   * from children recursively.
   */
  const processSidebarItems = (items: SidebarItem[]): SidebarItem[] => {
    return items.map((item) => ({
      ...item,
      pathList: getAllPaths(item), // Compute pathList for the current item
      children: item.children ? processSidebarItems(item.children) : [],
    }));
  };

  useEffect(() => {
    getSidebarItems({
      onSuccess: (response: { data: { sidebar_items: SidebarItem[] } }) => {
        const processedItems = processSidebarItems(response.data.sidebar_items);
        setSidebarItems(processedItems);
        setFilteredSidebarItems(processedItems);

        // Determinar los `openKeys` iniciales basado en `location.pathname`
        const keys = processedItems
          .filter((item) => getAllPaths(item).includes(location.pathname))
          .map((item) => item.id);
        setOpenKeys(keys); // Abre los menús relacionados
        setInitialOpenKeys(keys); // Guarda los `openKeys` iniciales
      },
      onError: (error: any) => {
        console.error('Error fetching sidebar items:', error);
      },
    });
  }, [location.pathname]);

  const handleSearchResults = (results: SidebarItem[], keysToOpen: number[]) => {
    setFilteredSidebarItems(results);
    setOpenKeys(keysToOpen);
  };

  const isOpen = (itemId: number) => openKeys.includes(itemId);

  const renderChildren = (item: SidebarItem) => {
    const icon = <DefaultIcon svgHTML={item.icon} />;

    return item.children && item.children.length > 0 ? (
      <SubMenuComponent
        key={item.id}
        pathList={item.pathList || []} // Use the precomputed pathList
        pathName={location.pathname}
        icon={icon}
        label={item.name}
        collapsed={!isOpen(item.id)}
        showingMainMenu={showingMainMenu}
      >
        {item.children.map((child) => renderChildren(child))}
      </SubMenuComponent>
    ) : (
      <MenuItem
        key={item.id}
        active={location.pathname === item.url}
        component={<Link to={item.url ?? '#'} />}
        icon={icon}
      >
        {item.name}
      </MenuItem>
    );
  };

  useEffect(() => {
    setMenuItemStyles(getMenuItemStyles(theme, collapsed));
  }, [theme, collapsed]);

  useEffect(() => {
    if (broken) {
      hideMainMenu && hideMainMenu();
      setCollapsed(false);
    } else {
      showMainMenu && showMainMenu();
    }
  }, [broken]);

  useEffect(() => {
    document.body.classList.toggle('vertical-collapsed', collapsed);
    document.body.classList.toggle('sidebar-enable', !collapsed);
  }, [collapsed]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, [collapsed]);

  useEffect(() => {
    if (!collapsed) {
      setAutoFocus(false);
    }
  }, [collapsed]);

  const toggleSearch = () => {
    setCollapsed(false);
    setAutoFocus(true);
  };

  return (
    <div style={{ position: 'fixed', display: 'flex', top: 70, bottom: 0, zIndex: 1000 }}>
      <MenuTop />
      <Sidebar
        collapsed={collapsed}
        toggled={showingMainMenu}
        onBackdropClick={hideMainMenu}
        onBreakPoint={setBroken}
        image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
        breakPoint="md"
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
      >
        {loading ? (
          <LazyLoading height="300" />
        ) : (
          <>
            {!broken && (
              <div
                style={{
                  padding: '0 18px',
                  marginBottom: '0px',
                  marginTop: '0px',
                  textAlign: 'left',
                }}
              >
                <button
                  type="button"
                  style={{ height: '50px' }}
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                  className="btn btn-sm font-size-16 header-item "
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars text-white" />
                </button>
              </div>
            )}

            <div style={{ padding: '0 20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {collapsed ? (
                <i
                  className="fa fa-search"
                  style={{
                    cursor: 'pointer',
                    fontSize: '24px',
                    color: themes[theme].menu.icon,
                    padding: '10px',
                    borderRadius: '50%',
                    backgroundColor: collapsed ? 'transparent' : hexToRgba(themes[theme].menu.menuContent, 0.1),
                  }}
                  onClick={toggleSearch}
                />
              ) : (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <SearchBar onSearchResults={handleSearchResults} sidebarItems={sidebarItems} autoFocus={autoFocus} initialOpenKeys={initialOpenKeys}  />
                </div>
              )}
            </div>

            <Menu menuItemStyles={menuItemStyles}>
              {filteredSidebarItems.length > 0 ? (
                filteredSidebarItems.map((item) => renderChildren(item))
              ) : (
                <MenuItem disabled>No se encontraron coincidencias</MenuItem>
              )}
            </Menu>
          </>
        )}
      </Sidebar>
    </div>
  );
};

export default SideBarPro;
