import React, { useState, useEffect, useCallback } from 'react';
import { SidebarItem } from '../../../app/models/SidebarItem';

interface SearchBarProps {
  onSearchResults: (results: SidebarItem[], openKeys: number[]) => void;
  sidebarItems: SidebarItem[];
  autoFocus?: boolean;
  initialOpenKeys: number[]; // Nuevo prop
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearchResults, sidebarItems, autoFocus, initialOpenKeys }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filterItems = useCallback((items: SidebarItem[], search: string): { results: SidebarItem[]; openKeys: number[] } => {
    const openKeys: number[] = [];
    const filteredResults = items.reduce<SidebarItem[]>((acc, item) => {
      if (item.name.toLowerCase().includes(search.toLowerCase())) {
        acc.push(item);
        if (item.parent_item_id) openKeys.push(item.parent_item_id);
      } else if (item.children) {
        const filteredChildren = filterItems(item.children, search);
        if (filteredChildren.results.length > 0) {
          acc.push({ ...item, children: filteredChildren.results });
          openKeys.push(item.id);
        }
      }
      return acc;
    }, []);
    return { results: filteredResults, openKeys };
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      onSearchResults(sidebarItems, initialOpenKeys); // Restaurar menús iniciales
    } else {
      const { results, openKeys } = filterItems(sidebarItems, searchTerm);
      onSearchResults(results, openKeys);
    }
  }, [searchTerm, sidebarItems, initialOpenKeys]);

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Buscar..."
        value={searchTerm}
        onChange={handleChange}
        className="search-input"
        autoFocus={autoFocus}
      />
      <button className="search-icon" />
    </div>
  );
};

export default SearchBar;
