import React from 'react';
import UserResponseDetailTable from './tables/UserResponseDetailTable';

interface Props {
    responses: any[];
    currentSectionName:string
}

const UserResponseConfirm = ({ 
    responses,
    currentSectionName
}: Props) => {

    return (
        <>
            <div className="mb-2">
                <strong>Sección:</strong> {currentSectionName}
            </div>
            <div>
                <UserResponseDetailTable responses={responses}/>
            </div>
        </>
    )
}

export default UserResponseConfirm;
