import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { User, UserDataForm, UserFilters, UserPasswordDataForm } from '../../models/User';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { nullToUndefinedRecursive } from '../../../utils/utils';
const useUserService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetUsers, setFetchingGetUsers] = useState(false);
    const [fetchingGetAllUsers, setFetchingGetAllUsers] = useState(false);
    const [fetchingShowUser, setFetchingShowUser] = useState(false);
    const [fetchingStoreUser, setFetchingStoreUser] = useState(false);
    const [fetchingEditUser, setFetchingEditUser] = useState(false);
    const [fetchingEditPasswordUser, setFetchingEditPasswordUser] = useState(false);
    const [fetchingCreateUser, setFetchingCreateUser] = useState(false);
    const [fetchingUpdateUser, setFetchingUpdateUser] = useState(false);
    const [fetchingUpdatePasswordUser, setFetchingUpdatePasswordUser] = useState(false);
    const [fetchingDeleteUser, setFetchingDeleteUser] = useState(false);
    const [fetchingActiveUser, setFetchingActiveUser] = useState(false);
    const [fetchingGetUser, setFetchingGetUser] = useState(false);

    const getUsers = (userFilters: UserFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(userFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.USERS.GET_USERS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetUsers
        });
    };

    const showUser = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USERS.SHOW_USER.replace(':id', id.toString()),
            setFetching: setFetchingShowUser
        });
    };

    const storeUser = (user: UserDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.USERS.STORE_USER,
            body: user,
            setFetching: setFetchingStoreUser
        });
    };

    const createUser = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USERS.CREATE_USER,
            setFetching: setFetchingCreateUser
        });
    };

    const editUser = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USERS.EDIT_USER.replace(':id', id.toString()),
            setFetching: setFetchingEditUser
        });
    };

    const updateUser = (id: number, user: UserDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.USERS.UPDATE_USER.replace(':id', id.toString()),
            body: user,
            setFetching: setFetchingUpdateUser
        });
    };

    const editPasswordUser = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USERS.EDIT_PASSWORD_USER.replace(':id', id.toString()),
            setFetching: setFetchingEditPasswordUser
        });
    };

    const updatePasswordUser = (
        id: number,
        user: UserPasswordDataForm,
        events: ServiceEvents = {}
    ) => {
        user = nullToUndefinedRecursive(user);

        doPut({
            ...events,
            url: EndPoints.USERS.UPDATE_PASSWORD_USER.replace(':id', id.toString()),
            body: user,
            setFetching: setFetchingUpdatePasswordUser
        });
    };

    const getUser = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USERS.GET_USER.replace(':id', id.toString()),
            setFetching: setFetchingGetUser
        });
    };

    const deleteUser = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.USERS.DELETE_USER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteUser
        });
    };

    const activeUser = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.USERS.ACTIVE_USER.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveUser
        });
    };

    const allUsers = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USERS.GET_USER_LIST,
            setFetching: setFetchingGetAllUsers
        });
    };

    return {
        fetchingGetUsers,
        fetchingShowUser,
        fetchingStoreUser,
        fetchingEditUser,
        fetchingEditPasswordUser,
        fetchingUpdateUser,
        fetchingUpdatePasswordUser,
        fetchingDeleteUser,
        fetchingCreateUser,
        fetchingActiveUser,
        fetchingGetUser,
        fetchingGetAllUsers,
        getUsers,
        showUser,
        storeUser,
        createUser,
        editUser,
        editPasswordUser,
        updateUser,
        updatePasswordUser,
        getUser,
        deleteUser,
        activeUser,
        allUsers
    };
};

export default useUserService;
