import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { SectionDataForm } from '../../../../app/models/Section';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useSectionService from '../../../../app/services/hooks/useSectionService';

interface GeneralProps {
    sectionForm: SectionDataForm;
    setSectionForm: (sectionForm: SectionDataForm) => void;
    errorFields?: any;
}

const SectionForm = ({
    sectionForm,
    setSectionForm,
    errorFields
}: GeneralProps) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { getNextSectionsBySurvey } = useSectionService();
    const [sections, setSections] = useState<SectionDataForm[]>([]);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        if(sectionForm.next_section){
            getNextSectionsBySurvey(sectionForm.id ? sectionForm.id : 0, sectionForm.survey_id!, {
                onSuccess: (response: ServiceResponse) => {
                    setSections(response.data.sections);
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                }
            });
        }
    }, [sectionForm.next_section]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        if(name === 'next_section'){
            setSectionForm({ 
                ...sectionForm, 
                next_section: !sectionForm.next_section,
                next_section_id: sectionForm.next_section ? null : sectionForm.next_section_id
            });
        } else{
            setSectionForm({ ...sectionForm, [name]: value });
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        if(value === undefined) value = null

        const obj = { [name]: value };

        setSectionForm({ ...sectionForm, ...obj });
    };

    if (!sectionForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Título</label>

                    <input
                        className={`form-control ${fieldHasError('title')}`}
                        type="text"
                        name="title"
                        id="title"
                        value={sectionForm.title}
                        onChange={handleChange}
                        placeholder="Ingrese título"
                        onFocus={() => onFocusRemove('title')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('title')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                <label className="col-form-label">Descripción</label>
                <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={sectionForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-12">
                    <label htmlFor="next_section" className="col-form-label">
                        Continua a otra Sección
                    </label>
                    <div className="square-switch d-flex  form-check form-switch">
                        <input
                            type="checkbox"
                            name="next_section"
                            id="next_section"
                            className={`form-check-input ${
                                sectionForm.next_section ? 'active' : ''
                            }`}
                            checked={sectionForm.next_section}
                            onChange={handleChange}
                        />
                        {fieldErrorMessage('next_section')}
                    </div>
                </div>
            </div>

            {
                sectionForm.next_section &&
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Seleccione siguiente Sección</label>
                        <SelectTwo
                            name="next_section_id"
                            id="next_section_id"
                            inputValue={sectionForm.next_section_id}
                            options={SelectTwoMapperOptions(sections, 'id', ['title'])}
                            hasError={fieldHasError('response_type') !== ''}
                            onChange={(value: any) => handleChangeSelectTwo('next_section_id', value?.value)}
                            placeholder={'Seleccione sección'}
                            onFocus={() => onFocusRemove('next_section_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('next_section_id')}
                        </div>
                    </div>
                </div>
            }
            
        </>
    );
};

export default SectionForm;
