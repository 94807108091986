import { Dict } from 'styled-components/dist/types';

interface ItemsProps {
    permissions: string[];
    paths: string[];
    to?: string;
}

export const ITEMS = {
    dashboard: {
        permissions: ['view_dashboard_extra'],
        paths: ['/dashboard'],
        to: '/dashboard'
    },
    calendar_me: {
        permissions: ['view_calendar_me_extra'],
        paths: ['/calendar'],
        to: '/calendar'
    },
    client: {
        permissions: ['view_client'],
        paths: ['/clients'],
        to: '/clients'
    },
    quotes: {
        permissions: ['view_quote'],
        paths: ['/quotes'],
        to: '/quotes'
    },
    work_order: {
        permissions: ['view_workorder'],
        paths: ['/work-orders'],
        to: '/work-orders'
    },
    transport_order: {
        permissions: ['view_transportorder'],
        paths: ['/transport-orders'],
        to: '/transport-orders'
    },
    transportation_orders: {
        permissions: ['view_transportationorder'],
        paths: ['/transportation-orders'],
        to: '/transportation-orders'
    },
    incident_reasons: {
        permissions: ['view_incidentreason'],
        paths: ['/incident-reasons'],
        to: '/incident-reasons'
    },
    guide: {
        permissions: ['view_guide'],
        paths: ['/guides'],
        to: '/guides'
    },
    load_ticket: {
        permissions: ['view_loadticket'],
        paths: ['/load-tickets'],
        to: '/load-tickets'
    },
    expense: {
        permissions: ['view_expense'],
        paths: ['/expenses'],
        to: '/expenses'
    },
    monthly_reconciliation: {
        permissions: ['view_monthlyreconciliation'],
        paths: ['/monthly-reconciliations'],
        to: '/monthly-reconciliations'
    },
    toll: {
        permissions: ['view_toll'],
        paths: ['/tolls'],
        to: '/tolls'
    },
    place: {
        permissions: ['view_place'],
        paths: ['/places'],
        to: '/places'
    },
    route_segment: {
        permissions: ['view_routesegment'],
        paths: ['/route-segments'],
        to: '/route-segments'
    },
    dispatch_programs: {
        permissions: ['view_dispatchprograms'],
        paths: ['/dispatch-programs'],
        to: '/dispatch-programs'
    },
    daily_carriers: {
        permissions: ['view_dailycarriers'],
        paths: ['/daily-carriers'],
        to: '/daily-carriers'
    },
    funds: {
        permissions: ['view_funds'],
        paths: ['/funds'],
        to: '/funds'
    },
    account: {
        permissions: ['view_account'],
        paths: ['/accounts'],
        to: '/accounts'
    },
    clasifications: {
        permissions: ['view_clasification'],
        paths: ['/clasifications'],
        to: '/clasifications'
    },
    carrier: {
        permissions: ['view_carrier'],
        paths: ['/carriers'],
        to: '/carriers'
    },
    truck: {
        permissions: ['view_truck'],
        paths: ['/trucks'],
        to: '/trucks'
    },
    hopper: {
        permissions: ['view_hopper'],
        paths: ['/hoppers'],
        to: '/hoppers'
    },
    fuel_consumption: {
        permissions: ['view_fuelconsumption'],
        paths: ['/fuel-consumptions'],
        to: '/fuel-consumptions'
    },
    provider: {
        permissions: ['view_provider'],
        paths: ['/providers'],
        to: '/providers'
    },
    provider_type: {
        permissions: ['view_providertype'],
        paths: ['/provider-types'],
        to: '/provider-types'
    },
    product: {
        permissions: ['view_product'],
        paths: ['/products'],
        to: '/products'
    },
    user: {
        permissions: ['view_user'],
        paths: ['/users'],
        to: '/users'
    },
    society: {
        permissions: ['view_society'],
        paths: ['/societies'],
        to: '/societies'
    },
    afp: {
        permissions: ['view_afp'],
        paths: ['/afps'],
        to: '/afps'
    },
    provision: {
        permissions: ['view_provision'],
        paths: ['/provisions'],
        to: '/provisions'
    },
    checking_account: {
        permissions: ['view_checkingaccount'],
        paths: ['/checking-accounts'],
        to: '/checking-accounts'
    },
    internal_settlement: {
        permissions: ['view_internalsettlement_extra'],
        paths: ['/internal-settlements'],
        to: '/internal-settlements'
    },
    bank: {
        permissions: ['view_bank'],
        paths: ['/banks'],
        to: '/banks'
    },
    transfer_reason: {
        permissions: ['view_transferreason'],
        paths: ['/transfer_reasons'],
        to: '/transfer_reasons'
    },
    account_type: {
        permissions: ['view_accounttype'],
        paths: ['/bank-account-types'],
        to: '/bank-account-types'
    },
    expense_type: {
        permissions: ['view_expensetype'],
        paths: ['/expense-types'],
        to: '/expense-types'
    },
    unit: {
        permissions: ['view_unit'],
        paths: ['/units'],
        to: '/units'
    },
    unit_equivalence: {
        permissions: ['view_unitequivalence'],
        paths: ['/unit-equivalence'],
        to: '/unit-equivalence'
    },
    currency: {
        permissions: ['view_currency'],
        paths: ['/currencies'],
        to: '/currencies'
    },
    foreign_exchanges: {
        permissions: ['view_foreignexchanges'],
        paths: ['/foreign-exchanges'],
        to: '/foreign-exchanges'
    },
    brand: {
        permissions: ['view_brand'],
        paths: ['/brands'],
        to: '/brands'
    },
    vehicle_model: {
        permissions: ['view_vehiclemodel'],
        paths: ['/vehicle-models'],
        to: '/vehicle-models'
    },
    group: {
        permissions: ['view_group'],
        paths: ['/roles'],
        to: '/roles'
    },
    document: {
        permissions: ['view_document'],
        paths: ['/documents'],
        to: '/documents'
    },
    document_set: {
        permissions: ['view_documentset'],
        paths: ['/document-sets'],
        to: '/document-sets'
    },
    holiday: {
        permissions: ['view_holiday'],
        paths: ['/holidays'],
        to: '/holidays'
    },
    event_types: {
        permissions: ['view_eventtype'],
        paths: ['/event-types'],
        to: '/event-types'
    },
    shift: {
        permissions: ['view_shiftwork'],
        paths: ['/shifts'],
        to: '/shifts'
    },
    calendar: {
        permissions: ['view_calendar'],
        paths: ['/calendar'],
        to: '/calendar'
    },
    worker: {
        permissions: ['view_worker'],
        paths: ['/workers'],
        to: '/workers'
    },
    contract: {
        permissions: ['view_contract'],
        paths: ['/contracts'],
        to: '/contracts'
    },
    liquidation: {
        permissions: ['view_liquidation'],
        paths: ['/liquidations'],
        to: '/liquidations'
    },
    bonus: {
        permissions: ['view_bonus'],
        paths: ['/bonus'],
        to: '/bonus'
    },
    deductions: {
        permissions: ['view_deductions'],
        paths: ['/deductions'],
        to: '/deductions'
    },
    job_title_liquidation_set: {
        permissions: ['view_deductions'],
        paths: ['/job_title_liquidation_set'],
        to: '/job_title_liquidation_set'
    },
    calendar_request: {
        permissions: ['view_calendarrequest'],
        paths: ['/calendar-requests'],
        to: '/calendar-requests'
    },
    travel_expense: {
        permissions: ['view_travelexpense'],
        paths: ['/travel-expenses'],
        to: '/travel-expenses'
    },
    job_titles: {
        permissions: ['view_jobtitle'],
        paths: ['/job-titles'],
        to: '/job-titles'
    },
    status_history: {
        permissions: ['view_statushistory'],
        paths: ['/status-histories'],
        to: '/status-histories'
    },
    survey: {
        permissions: ['view_survey'],
        paths: ['/surveys'],
        to: '/surveys'
    },
    question: {
        permissions: ['view_question'],
        paths: ['/questions'],
        to: '/questions'
    },
    monthly_closure: {
        permissions: ['view_monthlyclosure'],
        paths: ['/monthly-closures'],
        to: '/monthly-closures'
    },
    ai_tolls: {
        permissions: ['view_aitolls_extra'],
        paths: ['/ai-tolls'],
        to: '/ai-tolls'
    },
};

export const MENU_ITEMS = {
    no_module_single_items: {
        permissions: [...ITEMS.dashboard.permissions, ...ITEMS.calendar.permissions],
        paths: [...ITEMS.dashboard.paths, ...ITEMS.calendar.paths]
    },
    // ********** Sales submenu
    sales_single_items: {
        permissions: [
            ...ITEMS.client.permissions,
            ...ITEMS.quotes.permissions,
            ...ITEMS.transportation_orders.permissions,
            ...ITEMS.dispatch_programs.permissions
        ],
        paths: [
            ...ITEMS.client.paths,
            ...ITEMS.quotes.paths,
            ...ITEMS.transportation_orders.paths,
            ...ITEMS.dispatch_programs.paths
        ]
    },
    // ********** RRHH submenu
    human_resources_items: {
        permissions: [
            ...ITEMS.worker.permissions,
            ...ITEMS.calendar_request.permissions,
            ...ITEMS.status_history.permissions,
            ...ITEMS.contract.permissions,
            ...ITEMS.liquidation.permissions,

            ...ITEMS.shift.permissions,

        ],
        paths: [
            ...ITEMS.worker.paths,
            ...ITEMS.calendar_request.paths,
            ...ITEMS.status_history.paths,
            ...ITEMS.contract.paths,
            ...ITEMS.liquidation.paths,

            ...ITEMS.shift.paths,
        ]
    },

    // ********** Configuration RRHH Submenu
    human_resources_submenu_items: {
        permissions: [
            ...ITEMS.travel_expense.permissions,
            ...ITEMS.event_types.permissions,
            ...ITEMS.holiday.permissions,
            ...ITEMS.job_titles.permissions
        ],
        paths: [
            ...ITEMS.travel_expense.paths,
            ...ITEMS.event_types.paths,
            ...ITEMS.holiday.paths,
            ...ITEMS.job_titles.paths
        ]
    },
    liquidation_human_resources_submenu_items: {
        permissions: [
            ...ITEMS.bonus.permissions,
            ...ITEMS.deductions.permissions,
            ...ITEMS.job_title_liquidation_set.permissions
        ],
        paths: [
            ...ITEMS.bonus.paths,
            ...ITEMS.deductions.paths,
            ...ITEMS.job_title_liquidation_set.paths
        ]
    },
    // ********** Documents submenu
    management_items: {
        permissions: [
            ...ITEMS.document.permissions, 
            ...ITEMS.document_set.permissions, 
            ...ITEMS.monthly_closure.permissions,
            ...ITEMS.ai_tolls.permissions 
        ],
        paths: [
            ...ITEMS.document.paths, 
            ...ITEMS.document_set.paths, 
            ...ITEMS.monthly_closure.paths,
            ...ITEMS.ai_tolls.paths
        ]
    },
    // ********** Logistics submenu
    work_order_submenu_items: {
        permissions: [...ITEMS.work_order.permissions],
        paths: [...ITEMS.work_order.paths]
    },
    transport_order_submenu_items: {
        permissions: [
            ...ITEMS.transport_order.permissions,
            ...ITEMS.guide.permissions,
            ...ITEMS.load_ticket.permissions,
            ...ITEMS.expense.permissions,
            ...ITEMS.monthly_reconciliation.permissions,
            ...ITEMS.daily_carriers.permissions,
            ...ITEMS.funds.permissions
        ],
        paths: [
            ...ITEMS.transport_order.paths,
            ...ITEMS.guide.paths,
            ...ITEMS.load_ticket.paths,
            ...ITEMS.expense.paths,
            ...ITEMS.monthly_reconciliation.paths,
            ...ITEMS.daily_carriers.paths,
            ...ITEMS.funds.paths
        ]
    },
    funds: {
        permissions: [
            ...ITEMS.funds.permissions
        ],
        paths: [
            ...ITEMS.funds.paths
        ]
    },
    routes_submenu_items: {
        permissions: [
            ...ITEMS.toll.permissions,
            ...ITEMS.place.permissions,
            ...ITEMS.route_segment.permissions
        ],
        paths: [...ITEMS.toll.paths, ...ITEMS.place.paths, ...ITEMS.route_segment.paths]
    },
    logistics_single_items: {
        permissions: [
            ...ITEMS.carrier.permissions,
            ...ITEMS.truck.permissions,
            ...ITEMS.hopper.permissions
        ],
        paths: [...ITEMS.carrier.paths, ...ITEMS.truck.paths, ...ITEMS.hopper.paths]
    },
    // ********** Purchases submenu
    reports_submenu_items: {
        permissions: [...ITEMS.fuel_consumption.permissions],
        paths: [...ITEMS.fuel_consumption.paths]
    },
    purchases_single_items: {
        permissions: [...ITEMS.provider.permissions, ...ITEMS.provider_type.permissions],
        paths: [...ITEMS.provider.paths, ...ITEMS.provider_type.paths]
    },
    // ********** Inventary submenu
    inventary_single_items: {
        permissions: [...ITEMS.product.permissions],
        paths: [...ITEMS.product.paths]
    },
    // ********** checking_account submenu
    checking_account_single_items: {
        permissions: [
            ...ITEMS.checking_account.permissions,
            ...ITEMS.internal_settlement.permissions
        ],
        paths: [...ITEMS.checking_account.paths, ...ITEMS.internal_settlement.paths]
    },

    //***** Configuration  submenu
    configuration_single_items: {
        permissions: [
            ...ITEMS.user.permissions,
            ...ITEMS.group.permissions,
            ...ITEMS.status_history.permissions,
            ...ITEMS.survey.permissions,
            ...ITEMS.question.permissions,
            ...ITEMS.incident_reasons.permissions,
            
        ],
        paths: [
            ...ITEMS.user.paths, 
            ...ITEMS.group.paths, 
            ...ITEMS.status_history.paths,
            ...ITEMS.survey.paths,
            ...ITEMS.question.paths,
            ...ITEMS.incident_reasons.paths,
        ]
    },
    organization_and_societies_items: {
        permissions: [
            ...ITEMS.society.permissions,
            ...ITEMS.afp.permissions,
            ...ITEMS.provision.permissions
        ],
        paths: [...ITEMS.society.paths, ...ITEMS.afp.paths, ...ITEMS.provision.paths]
    },
    finance_and_accounts_items: {
        permissions: [
            ...ITEMS.bank.permissions,
            ...ITEMS.transfer_reason.permissions,
            ...ITEMS.account_type.permissions,
            ...ITEMS.account.permissions,
            ...ITEMS.clasifications.permissions,
            ...ITEMS.currency.permissions,
            ...ITEMS.foreign_exchanges.permissions
        ],
        paths: [
            ...ITEMS.bank.paths,
            ...ITEMS.transfer_reason.paths,
            ...ITEMS.account_type.paths,
            ...ITEMS.account.paths,
            ...ITEMS.clasifications.paths,
            ...ITEMS.currency.paths,
            ...ITEMS.foreign_exchanges.paths
        ]
    },
    expenses_and_purchase: {
        permissions: [...ITEMS.expense_type.permissions],
        paths: [...ITEMS.expense_type.paths]
    },
    vehicles: {
        permissions: [...ITEMS.brand.permissions, ...ITEMS.vehicle_model.permissions],
        paths: [...ITEMS.brand.paths, ...ITEMS.vehicle_model.paths]
    },
    measurement_units: {
        permissions: [...ITEMS.unit.permissions, ...ITEMS.unit_equivalence.permissions],
        paths: [...ITEMS.unit.paths, ...ITEMS.unit_equivalence.paths]
    }
};

// **********ALL MODULES **********

const sales_module: ItemsProps[] = [MENU_ITEMS.sales_single_items];

const logistics_module: ItemsProps[] = [
    MENU_ITEMS.work_order_submenu_items,
    MENU_ITEMS.transport_order_submenu_items,
    MENU_ITEMS.routes_submenu_items,
    MENU_ITEMS.logistics_single_items
];

const purchases_module: ItemsProps[] = [
    MENU_ITEMS.reports_submenu_items,
    MENU_ITEMS.purchases_single_items
];

const inventary_module: ItemsProps[] = [MENU_ITEMS.inventary_single_items];

const checking_account_module: ItemsProps[] = [MENU_ITEMS.checking_account_single_items];

const configuration_module: ItemsProps[] = [MENU_ITEMS.configuration_single_items];

const human_resources_module: ItemsProps[] = [MENU_ITEMS.human_resources_items];

const management_module: ItemsProps[] = [MENU_ITEMS.management_items];

export const PERMISSIONS_APP = {
    single_items: [MENU_ITEMS.no_module_single_items],
    sales: sales_module,
    logistics: logistics_module,
    purchases: purchases_module,
    inventary: inventary_module,
    checking_account: checking_account_module,
    configuration: configuration_module,
    human_resources: human_resources_module,
    management: management_module
};

export const verify_permission = (
    modulePermissions: ItemsProps[],
    permissionsUser: Array<string>
) => {
    for (const module of modulePermissions) {
        for (const userPermission of permissionsUser) {
            if (module.permissions.includes(userPermission)) {
                return true;
            }
        }
    }
    return false;
};

export const verify_path = (modulePaths: string[], pathname: string) => {
    if (modulePaths.some((path) => pathname.includes(path))) {
        return true;
    }

    return false;
};
