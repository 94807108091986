import { EventTypeListItem } from "./EventType";
import { Section } from "./Section";

export interface Survey{

    id?: number;
    title?: string;
    description?: string;
    sections?: Section[];
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;

}

export interface SurveyDataForm{

    id?: number;
    title?: string;
    description?: string;
    event_types?: EventTypeListItem[];
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    section_ids?: number[];

}

export const defaultSurveyDataForm: SurveyDataForm = {
    title: '',
    description: ''
}

export const defaultSurvey: Survey = {
    title: '',
    description: ''
}


export interface SurveyFilters {
    title?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}