import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { RouterParams } from '../../../../app/shared/interfaces';
import Breadcrumbs, {
    BreadcrumbsItem
} from '../../../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../../../components/default/DefaultCard';
import { CalendarEventDataForm, defaultCalendarEventDataForm } from '../../../../app/models/Event';
import { AppContext } from '../../../../contexts/AppContext';
import WorkerEventForm from '../../../Workers/components/forms/WorkerEventForm';
import CalendarExpensesContainer from '../containers/CalendarExpensesContainer';
import useEventService from '../../../../app/services/hooks/useEventService';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import {
    Shift,
    ShiftWorkDayTravelExpense,
    defaultShiftDataForm
} from '../../../../app/models/Shift';
import DefaultModal from '../../../../components/default/DefaultModal';
import ButtonCreate from '../../../../components/buttons/ButtonCreate';
import CalendarEventEdit from '../containers/CalendarEventEdit';
import {
    TravelExpenseDataForm,
    defaultTravelExpenseDataForm
} from '../../../../app/models/TravelExpense';
import { getTimeFromDateTime, momentParseDate } from '../../../../helpers';
import LazyLoading from '../../../../components/LazyLoading';
import WorkerEventErase from '../../../Workers/components/WorkerEventErase';
import ButtonDeleteForm from '../../../../components/buttons/ButtonDeleteForm';
import WorkerShiftWorkUpdateOption from '../../../Workers/components/WorkerShiftWorkUpdateOption';
import useNavigationPage from '../../../../hooks/useNavigationPage';
import ExpensesAndChecklists from '../containers/ExpensesAndChecklists';

const CalendarWorkerShow = () => {
    const { id } = useParams<RouterParams>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const startDate = queryParams.get('start_date');
    const endDate = queryParams.get('end_date');
    const admin = queryParams.get('admin') === 'true';
    const workerId = queryParams.get('worker');
    const { navigationPage } = useNavigationPage();

    const { getEventsById, fetchingEventById, deleteOneDayShiftWorkEvent } = useEventService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [showingEdit, setShowingEdit] = useState(false);
    const [showEraseModal, setShowEraseModal] = useState(false);
    const [showShiftWorkUpdateModal, setShowShiftWorkUpdateModal] = useState(false);
    const [title, setTitle] = useState<string>('');
    const [event, setEvent] = useState<any>();
    const [selectedEvent, setSelectedEvent] = useState<CalendarEventDataForm>({
        ...defaultCalendarEventDataForm
    });
    const [parentEvent, setParentEvent] = useState<CalendarEventDataForm>(
        defaultCalendarEventDataForm
    );
    const [shiftWorkEvent, setShiftWorkEvent] = useState<CalendarEventDataForm>(
        defaultCalendarEventDataForm
    );
    const [selectedTravelExpense, setSelectedTravelExpense] = useState<TravelExpenseDataForm>(
        defaultTravelExpenseDataForm
    );
    const [eventTypes, setEventTypes] = useState<any[]>([]);
    const [shiftWorkDayTravelExpenses, setShiftWorkDayTravelExpenses] = useState<
        ShiftWorkDayTravelExpense[]
    >([]);
    const [shiftWorks, setShiftWorks] = useState<Shift[]>([defaultShiftDataForm]);
    const [shiftWork, setShiftWork] = useState<Shift>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Evento #' + id,
            url: '/calendar-by-worker/' + id + '/event',
            isActive: true
        }
    ];

    useEffect(() => {
        if (startDate) {
            getEvent(parseInt(id), startDate);
        }
    }, [id, startDate]);

    const getEvent = (id: number, date: string) => {
        getEventsById(id, date, {
            onSuccess: (response: ServiceResponse) => {
                setTitle(response.data.event.title);
                setEventTypes([response.data.event.event_type]);
                setShiftWorks([response.data.event.shift_work]);
                setShiftWork(response.data.shift_work);
                setShiftWorkDayTravelExpenses(response.data.shift_work_day_travel_expense);
                setSelectedTravelExpense(response.data.event.travel_expense);
                const _event = {
                    ...response.data.event,
                    event_type_id: response.data.event.event_type.id,
                    start_date: startDate,
                    end_date: endDate,
                    start_hour: getTimeFromDateTime(response.data.event.start_date),
                    end_hour: getTimeFromDateTime(response.data.event.end_date)
                };
                setSelectedEvent(_event);
                setEvent(_event);
                setParentEvent(response.data.database_event);
                setShiftWorkEvent(response.data.database_event);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const deleteEvent = (id: number, date: string) => {
        deleteOneDayShiftWorkEvent(id, date, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                navigationPage(`/workers/${workerId}/edit?defaultActiveKey=calendar`);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const showConfirmAlert = () => {
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: `Está a punto de eliminar el evento ${title} del ${momentParseDate(
                startDate
            )}`,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Modificando jornada...');
                        if (startDate) deleteEvent(parseInt(id), startDate);
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El evento no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const showEdit = () => {
        setShowingEdit(true);
    };

    const hideEdit = () => {
        setShowingEdit(false);
    };

    const cancel = () => {
        setShowingEdit(false);
        setSelectedEvent(event);
        setParentEvent(shiftWorkEvent);
    };

    const showErase = () => {
        if (shiftWorkEvent.is_shift_work) {
            setShowEraseModal(true);
        } else {
            showConfirmAlert();
        }
    };

    const cancelErase = () => {
        setShowEraseModal(false);
    };

    const cancelShiftWorkUpdate = () => {
        setShowShiftWorkUpdateModal(false);
    };

    const showShiftWork = () => {
        setShowingEdit(false);
        setShowShiftWorkUpdateModal(true);
    };

    const cancelShiftWork = () => {
        setShowShiftWorkUpdateModal(false);
        setSelectedEvent(event);
        setParentEvent(shiftWorkEvent);
    };

    return (
        <>
            <Breadcrumbs pageSection={`Evento #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-5">
                    <DefaultCard>
                        {shiftWork ? (
                            <div className="h3 mt-0 mb-4 card-title">
                                Datos de la jornada: {shiftWork.name}
                            </div>
                        ) : (
                            <div className="h3 mt-0 mb-4 card-title">Datos del evento: {title}</div>
                        )}
                        {fetchingEventById ? (
                            <LazyLoading height="300" />
                        ) : (
                            <WorkerEventForm
                                calendarEventDataForm={selectedEvent}
                                setCalendarEventDataForm={setSelectedEvent}
                                eventTypes={eventTypes}
                                shiftWorks={shiftWorks}
                                travelExpenses={[selectedTravelExpense]}
                                selectedTravelExpense={selectedTravelExpense}
                                selectedEventDate={selectedEvent.start_date}
                                selectedEventDateEnd={selectedEvent.end_date}
                                selectedStartHour={selectedEvent.start_hour}
                                selectedEndHour={selectedEvent.end_hour}
                                editable={false}
                            />
                        )}
                        <hr />
                        {admin && (
                            <div className="row justify-content-end">
                                <div className="col-auto">
                                    <ButtonDeleteForm
                                        callbackDelete={showErase}
                                        title="Eliminar Evento"
                                    />
                                </div>
                                <div className="col-auto">
                                    <ButtonCreate callbackCreate={showEdit} title="Editar Evento" />
                                </div>
                            </div>
                        )}
                        {/* <div className="row ">
                            <div className="col-auto">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm "
                                    onClick={showChecklist}
                                >
                                    <i className="mdi mdi-clipboard-list me-1"/>
                                    Contestar Encuesta
                                </button>
                            </div>
                            {admin && (
                                <div className='col'>
                                    <div className='row justify-content-end'>
                                        <div className="col-auto">
                                            <ButtonDeleteForm
                                                callbackDelete={showErase}
                                                title="Eliminar Evento"
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <ButtonCreate callbackCreate={showEdit} title="Editar Evento" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div> */}
                    </DefaultCard>
                </div>
            </div>
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={cancel}
                    title="Editar Evento"
                    backdrop={true}
                    showFooter={false}
                >
                    <CalendarEventEdit
                        eventId={parseInt(id)}
                        workerId={parseInt(workerId!)}
                        selectedEvent={selectedEvent}
                        setCalendarEventDataForm={setSelectedEvent}
                        selectedTravelExpense={selectedTravelExpense}
                        shiftWorkDayTravelExpenses={shiftWorkDayTravelExpenses}
                        parentEvent={parentEvent}
                        setParentEvent={setParentEvent}
                        shiftWorkUpdate={showShiftWork}
                        onError={hideEdit}
                        onCancel={() => cancel()}
                    />
                </DefaultModal>
            ) : null}
            {showEraseModal ? (
                <DefaultModal
                    show={showEraseModal}
                    handleClose={cancelErase}
                    title="Eliminar Jornada"
                    backdrop={true}
                    showFooter={false}
                >
                    <WorkerEventErase
                        eventId={parseInt(id)}
                        workerId={parseInt(workerId!)}
                        selectedDate={startDate!}
                        onCancel={() => cancelErase()}
                    />
                </DefaultModal>
            ) : null}
            {showShiftWorkUpdateModal ? (
                <DefaultModal
                    show={showShiftWorkUpdateModal}
                    handleClose={cancelShiftWork}
                    title="Editar Jornada"
                    backdrop={true}
                    showFooter={false}
                >
                    <WorkerShiftWorkUpdateOption
                        ShiftWork={parentEvent}
                        workerId={parseInt(workerId!)}
                        selectedDate={startDate!}
                        onCancel={() => cancelShiftWorkUpdate()}
                    />
                </DefaultModal>
            ) : null}
            <>
                {/* <CalendarExpensesContainer
                    eventId={parseInt(id)}
                    calendarDate={startDate!}
                    admin={admin}
                /> */}
                <DefaultCard>
                    <ExpensesAndChecklists
                        eventId={parseInt(id)}
                        eventTypeId={parseInt(event?.event_type?.id)}
                        calendarDate={startDate!}
                        admin={admin}
                        workerId={Number(workerId)}
                    />
                </DefaultCard>
            </>
        </>
    );
};

export default CalendarWorkerShow;
