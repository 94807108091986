import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import moment from 'moment';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import DefaultModal from '../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import useNavigationPage from '../../hooks/useNavigationPage';
import {
    StatusHistory,
    StatusHistoryCode,
    StatusHistoryCodeFilters,
    StatusHistoryFilters
} from '../../app/models/StatusHistory';
import useStatusHistory from '../../app/services/hooks/useStatusHistoryService';
import StatusHistoryDataTable from './components/tables/StatusHistoryDataTable';
import { Tab, Tabs } from 'react-bootstrap';
import StatusHistoryCodesDataTable from './components/tables/StatusHistoryCodesDataTable';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Bitácora de estados',
        url: '/status-histories',
        isActive: true
    }
];

const StatusHistories = () => {
    const [statusHistories, setStatusHistories] = useState<StatusHistory[]>([]);
    const [statusHistoryCodes, setStatusHistoryCodes] = useState<StatusHistoryCode[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [totalRowsCodes, setTotalRowsCodes] = useState<number>(0);
    const { auth } = useContext(AuthContext);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();

    const {
        fetchingGetStatusHistories,
        fetchingGetStatusHistoryCodes,
        getStatusHistories,
        getStatusHistoryCodes
    } = useStatusHistory();

    const [filter, setFilter] = useState<StatusHistoryFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const [statusHistoryCodefilter, setStatusHistoryCodefilter] =
        useState<StatusHistoryCodeFilters>({
            page: 1,
            per_page: 10,
            sort: 'id',
            order: 'asc'
        });

    useEffect(() => {
        getAllStatusHistoryCodes();
    }, [statusHistoryCodefilter.page, statusHistoryCodefilter.per_page, statusHistoryCodefilter.sort, statusHistoryCodefilter.order]);

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const getAllStatusHistoryCodes = () => {
        getStatusHistoryCodes(statusHistoryCodefilter, {
            onSuccess: (response: ServiceResponse) => {
                setStatusHistoryCodes(response.data.status_history_codes);
                setTotalRowsCodes(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2500
                });
            }
        });
    };

    const reloadTable = () => {
        getStatusHistories(filter, {
            onSuccess: (response: ServiceResponse) => {
                setStatusHistories(response.data.status_histories);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2500
                });
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Bitácora de estados" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <Tabs defaultActiveKey="general-data" id="status-history-tabs" className="mb-3">
                    <Tab eventKey="general-data" title="General">
                        <div className="row">
                            <div className="col-12">
                                <StatusHistoryDataTable
                                    statusHistories={statusHistories}
                                    totalRows={totalRows}
                                    loading={fetchingGetStatusHistories}
                                    filter={filter}
                                    setFilter={setFilter}
                                    onSearch={reloadTable}
                                />
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="glosary-data" title="Códigos">
                        <div className="row">
                            <div className="col-12">
                                <StatusHistoryCodesDataTable
                                    statusHistoryCodes={statusHistoryCodes}
                                    totalRows={totalRowsCodes}
                                    loading={fetchingGetStatusHistoryCodes}
                                    filter={statusHistoryCodefilter}
                                    setFilter={setStatusHistoryCodefilter}
                                    onSearch={getAllStatusHistoryCodes}
                                />
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </DefaultCard>
        </>
    );
};

export default StatusHistories;
