import React, { useEffect, useState } from 'react';
import useUserResponseDetailService from '../../../app/services/hooks/useUserResponseDetail';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { UserResponseDetail } from '../../../app/models/UserResponseDetail';
import LazyLoading from '../../../components/LazyLoading';
import UserResponseDetailTable from './tables/UserResponseDetailTable';

interface Props {
    userResponseId: number;
}

const UserResponseDetailContainer = ({ userResponseId }: Props) => {

    const { getAllUserResponseDetailsByUserResponse, fetchingGetUserResponseDetailsByUserResponse } = useUserResponseDetailService();

    const [ responseDetail, setResponseDetail ] = useState<UserResponseDetail[]>([])
    
    useEffect(() => {
        getAllUserResponseDetailsByUserResponse(userResponseId, {
            onSuccess: (response: ServiceResponse) => {
                setResponseDetail(response.data.response_detail);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
            }
        })
    },[ userResponseId ]);

    return fetchingGetUserResponseDetailsByUserResponse ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <UserResponseDetailTable responses={responseDetail}/>
        </>
    )
}

export default UserResponseDetailContainer;
