import { useEffect, useState, useContext } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import { AuthContext } from '../../contexts/AuthContext';
import DefaultModal from '../../components/default/DefaultModal';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { AppContext } from '../../contexts/AppContext';
import { toast } from 'react-toastify';
import TravelExpensesDataTable from './components/tables/TravelExpensesDataTable';
import {
    TravelExpense,
    TravelExpenseDataForm,
    TravelExpenseFilters
} from '../../app/models/TravelExpense';
import useTravelExpenseService from '../../app/services/hooks/useTravelExpenseService';
import useNavigationPage from '../../hooks/useNavigationPage';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import TravelExpenseCreate from './components/TravelExpenseCreate';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import TravelExpenseEdit from './components/TravelExpenseEdit';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Viáticos',
        url: '/travel-expenses',
        isActive: true
    }
];

const TravelExpenses = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetTravelExpenses, getTravelExpenses, deleteTravelExpense } =
        useTravelExpenseService();
    const [travelExpenses, setTravelExpenses] = useState<TravelExpenseDataForm[]>([]);
    const [travelExpenseIdEdit, setTravelExpenseIdEdit] = useState<number>(-1);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [travelExpenseFilter, setTravelExpenseFilter] = useState<TravelExpenseFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [travelExpenseFilter.page, travelExpenseFilter.per_page, travelExpenseFilter.sort, travelExpenseFilter.order]);

    const getAllTravelExpenses = () => {
        getTravelExpenses(travelExpenseFilter, {
            onSuccess: (response: ServiceResponse) => {
                setTravelExpenses(response.data.travel_expense);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const reloadTable = () => {
        getAllTravelExpenses();
    };

    const showEdit = (travelExpenseIdEdit: number) => {
        setShowingEdit(true);
        setTravelExpenseIdEdit(travelExpenseIdEdit);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setTravelExpenseIdEdit(-1);
    };

    const onError = () => {
        setShowingEdit(false);
        setTravelExpenseIdEdit(-1);
    };

    const destroy = (travelExpenseId: number) => {
        const _text = 'Está a punto de eliminar el viático #' + travelExpenseId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando viático...');
                        deleteTravelExpense(travelExpenseId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El viático no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Viáticos" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="col-12">
                    <div className="row">
                        <div className="col">
                            <ButtonCreate callbackCreate={showCreate} title="Crear viático" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TravelExpensesDataTable
                            travelExpenses={travelExpenses}
                            totalRows={totalRows}
                            filter={travelExpenseFilter}
                            loading={fetchingGetTravelExpenses}
                            setFilter={setTravelExpenseFilter}
                            callbackSuccess={reloadTable}
                            destroy={destroy}
                            edit={showEdit}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear viático"
                    backdrop={true}
                    showFooter={false}
                >
                    <TravelExpenseCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar viático"
                    backdrop={true}
                    showFooter={false}
                >
                    <TravelExpenseEdit
                        travelExpenseId={travelExpenseIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={onError}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TravelExpenses;
