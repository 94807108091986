import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { Survey } from '../../../app/models/Survey';
import { defaultQuestionDataForm, QuestionDataForm } from '../../../app/models/Question';
import { Section } from '../../../app/models/Section';
import QuestionCard from '../components/QuestionCard';
import NavQuestions from '../components/NavQuestions';
import UserResponseConfirm from '../components/UserResponseConfirm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { InputResponseType } from '../../../app/shared/enums';
import { UserResponseFileDataForm } from '../../../app/models/UserResponseFile';

interface Props {
    survey: Survey;
    sections: Section[];
    allQuestions: QuestionDataForm[];
    checklistQuestions: QuestionDataForm[];
    setChecklistQuestions: (questions:QuestionDataForm[])=>void;
    historyQuestions: QuestionDataForm[];
    setHistoryQuestions: (questions:QuestionDataForm[])=>void;
    storeUserResponse: (userResponse:any)=>void;
    nextSectionId: number | null | undefined;
    setNextSectionId: (number:number | undefined)=>void;
    hasNextSection: boolean;
    setHasNextSection: (hasNextSection:boolean)=>void;
    userResponseId: number | undefined;
    currentSectionName: string;
    calendarDate: string
}

const RespondSurvey = ({ 
    survey, 
    sections,
    allQuestions,
    checklistQuestions,
    setChecklistQuestions,
    historyQuestions,
    setHistoryQuestions,
    storeUserResponse,
    nextSectionId,
    setNextSectionId,
    hasNextSection,
    setHasNextSection,
    userResponseId,
    currentSectionName,
    calendarDate
}: Props) => {

    const { showLoading, hideLoading } = useContext(AppContext);

    const [ answeredQuestions, setAnsweredQuestions ] = useState<QuestionDataForm[]>([]);
    const [ nextSection, setNextSection ] = useState<Section>();
    
    const [ currentQuestion, setCurrentQuestion ] = useState<number>(1);
    const [ selectedQuestion, setSelectedQuestion ] = useState<QuestionDataForm>(defaultQuestionDataForm);
    const [ showResume, setShowResume ] = useState<boolean>(false);
    const [ showConfirm, setShowConfirm ] = useState<boolean>(false);
    
    //useEffect para setear la primera pregunta
    useEffect(() => {
        if (historyQuestions.length === 1) {
            setSelectedQuestion(historyQuestions[0]);
        }
    }, [historyQuestions]);

    //useEffect para setear la siguiente seccion
    useEffect(() => {
        const nxtStion = sections.find(sec => sec.id === nextSectionId)

        if(nxtStion?.questions && nxtStion?.questions.length > 0) {
            setNextSection(nxtStion)
            setHasNextSection(true)
        }else {
            setNextSection(undefined)
            setHasNextSection(false)
        }
    }, [nextSectionId]);


    // useEffect que escucha la respuesta y revisa si hay next_question para agregar la nueva seccion a la lista de preguntas, 
    // y guarda la respuesta en el histórico
    useEffect(() => {

        if(showConfirm) return;

        if(selectedQuestion && selectedQuestion.response_type === InputResponseType.RADIOBUTTON 
            || selectedQuestion.response_type === InputResponseType.SELECT){

            const selectedOption = selectedQuestion.options?.find(op => op.is_response === true);
            const thisChckLstSoFar = [...answeredQuestions];
            thisChckLstSoFar.push(selectedQuestion);

            if(selectedOption?.next_question !== null){
                const nxtQuestion = allQuestions.find(q => q.id === selectedOption?.next_question_id);
                const nxtQtnSection = sections?.find(scn => scn.id === nxtQuestion?.section_id);
                const nxtQuestionIndex = nxtQtnSection?.questions?.findIndex(q => q.id === nxtQuestion?.id);
                const nxtSctnQuestions = nxtQtnSection?.questions?.slice(nxtQuestionIndex);

                if(nxtSctnQuestions){
                    setChecklistQuestions([
                        ...thisChckLstSoFar,
                        ...nxtSctnQuestions
                    ])
                }

            }else {
                const thisSctn = sections?.find(scn => scn.id === selectedQuestion.section_id);
                const selectedQstnIndexInThisScn = thisSctn?.questions?.findIndex(q => q.id === selectedQuestion.id);
                const thisSctnNxtQstns = thisSctn?.questions?.slice(selectedQstnIndexInThisScn);

                if(thisSctnNxtQstns && thisSctnNxtQstns.length > 1){
                    thisSctnNxtQstns.shift();
                    setChecklistQuestions([
                        ...thisChckLstSoFar,
                        ...thisSctnNxtQstns
                    ]);
                }else {
                    setChecklistQuestions([
                        ...thisChckLstSoFar,
                    ]);
                }
            }
        }

        const modifiedQuestions = historyQuestions.map(q => {
            if (q.id === selectedQuestion.id) return selectedQuestion
            else return q
        })

        setHistoryQuestions(modifiedQuestions);

    }, [selectedQuestion]);

    // useEffect que escucha el número de pregunta y cambia la pregunta para mostrarlo
    // y guarda la respuesta de la pregunta anterior en la lista de respuestas
    useEffect(() => {

        const questionToShow = checklistQuestions[currentQuestion-1];

        if (questionToShow){
            const historyAnswerd = historyQuestions.find(q => q.id === questionToShow.id );

            if (historyAnswerd){
                setSelectedQuestion(historyAnswerd);
            } else{
                setSelectedQuestion(questionToShow);
                historyQuestions.push(questionToShow)
            }
        } 

        if(historyQuestions.length > 0){

            const lastQuestion = checklistQuestions[currentQuestion-2]

            if(lastQuestion){
                const lastAnswrd = historyQuestions.find(q => q.id === lastQuestion.id );

                if(lastAnswrd){
                    const answerds = [
                        ...answeredQuestions,
                        lastAnswrd
                    ]
                    setAnsweredQuestions(answerds)
                }
            }

            if(answeredQuestions.length >= currentQuestion){
                const slicedAnswerds = answeredQuestions.slice(0, currentQuestion-1);
                setAnsweredQuestions(slicedAnswerds);
            }
        }
        
    }, [currentQuestion]);

    useEffect(() => {
        if(showConfirm && checklistQuestions.length === 1){
            setAnsweredQuestions(historyQuestions)
        }
    }, [showConfirm]);


    const confirmForm = () => {
        setShowResume(true);
    };

    //Funcion que prepara las preguntas y archivos para enviar el request del store
    const prepareRequest = () => {
        const questionsWithImages = answeredQuestions.filter(
            question => question.selectedImages && question.selectedImages.length > 0
        );
        
        const questionsWithoutImages = answeredQuestions.filter(
            question => question.response_type !== InputResponseType.FILE
        );

        const files: UserResponseFileDataForm[] = []
        questionsWithImages.forEach((q) => {
            q.selectedImages?.forEach((file)=> {
                const newFile: UserResponseFileDataForm = {
                    question_id: q.id,
                    question_text: q.question_text,
                    file: file
                }
                files.push(newFile)
            })
        });

        const userResponseDataForm = {
            questions: JSON.stringify(questionsWithoutImages),
            survey_id: survey?.id,
            survey_json: JSON.stringify(survey),
            user_response_id: userResponseId ? userResponseId : undefined,
            next_section: hasNextSection,
            next_section_id: nextSection ? nextSection.id : undefined,
            files: files,
            calendar_date: calendarDate
        }

        return userResponseDataForm
    }


    const store = () => {
        const _text = 'Está a punto de enviar la encuesta #' + survey?.title;
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Enviando encuesta...');
                        const userResponse = prepareRequest();
                        storeUserResponse(userResponse);
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La encuesta no se ha enviado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };
    

    return (
        <>
            {!showConfirm  && <div className="mb-2">
                <strong>Sección:</strong> {currentSectionName}
            </div>}
            <div className="">
                { selectedQuestion && !showResume ? (
                    <QuestionCard 
                        currentSectionName={currentSectionName}
                        currentQuestion={selectedQuestion} 
                        setCurrentQuestion={setSelectedQuestion}
                        showConfirm={showConfirm}
                        isSurveyResponse={true}
                        nextSection={hasNextSection}
                    />
                ) : null }
                { showResume ? (
                    <UserResponseConfirm 
                        responses={answeredQuestions} 
                        currentSectionName={currentSectionName}
                    />
                ) : null }
            </div>
            <div className="">
                <NavQuestions 
                    totalQuestions={checklistQuestions.length} 
                    currentQuestion={currentQuestion} 
                    setCurrentQuestion={setCurrentQuestion}
                    showResume={showResume}
                    setShowResume={setShowResume}
                    confirmForm={confirmForm}
                    setShowConfirm={setShowConfirm}
                    storeForm={store}
                    showConfirm={showConfirm}
                    questionSelected={selectedQuestion}
                />
            </div>
        </>
    )
}

export default RespondSurvey


