import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { WorkerDocument } from '../../models/Workers';
import { DocumentNameFilters } from '../../models/Document';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import { fetchDefaultHeaders } from '../shared/vars';


const useWorkerDocumentService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    
    const [fetchingGetWorkerDocuments, setFetchingGetWorkerDocuments] = useState(false);
    const [fetchingStoreWorkerDocument, setFetchingStoreWorkerDocument] = useState(false);
    const [
        fetchingGetWorkerDocumentHistoryByWrokerAndDocumentType,
        setFetchingGetWorkerDocumentHistoryByWorkerAndDocumentType
    ] = useState(false);
    const [fetchingGetWorkerDocumentsByset, setFetchingGetWorkerDocumentBySet] = useState(false);


    const getWorkerDocumentsByWorker = (worker_id: number, filters: DocumentNameFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = EndPoints.WORKER_DOCUMENTS.GET_WORKER_DOCUMENTS_BY_WORKER.replace(
            ':id',
            worker_id.toString()
        );
        doGet({
            ...events,
            url: `${url}?${queryString}`,
            setFetching: setFetchingGetWorkerDocuments
        });
    };

    const getWorkerDocumentBySet = (workerId: number, documentSetId: number) => {
        if (showLoading) showLoading('download', 'Preparando archivos...');
    
        const url = EndPoints.WORKER_DOCUMENTS.GET_BY_SET.replace(
            ':worker_id',
            workerId.toString()
        ).concat(`?document_set=${documentSetId}`);
    
        fetch(url, { headers: { ...fetchDefaultHeaders } })
            .then(async (response) => {
                // Leer encabezados del backend
                const message = response.headers.get('Message') || ''; // Texto plano
                const messageJson = response.headers.get('Message-Json') || ''; // JSON estructurado
    
                // Verificar si la respuesta fue exitosa
                if (!response.ok) {
                    const errorResponse = await response.json();
                    throw new Error(
                        errorResponse.message ||
                        message ||
                        'Error al intentar descargar el archivo ZIP.'
                    );
                }
    
                // Leer el archivo blob (ZIP)
                const blob = await response.blob();

                const parsedMessageJson = messageJson ? JSON.parse(messageJson) : null;
                const userRut = parsedMessageJson?.user_rut || '';
                const today = parsedMessageJson?.today || '';


                const fileName = `DocsUsuario_${userRut}_${today}.zip`;
                return { blob, message, messageJson,fileName };
            })
            .then(({ blob, message, messageJson, fileName }) => {
                // Descargar el archivo ZIP
                const fileUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = fileUrl;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(fileUrl);
    
                if (changeAnimation) changeAnimation('downloadSuccess', 'Archivos descargados con éxito', true);
                if (hideLoading) hideLoading();
    
                // Procesar `Message-Json`
                const parsedMessageJson = messageJson ? JSON.parse(messageJson) : null;
                const messageList = parsedMessageJson?.downloaded_files || [];
                const failedList = parsedMessageJson?.failed_files || [];
    
                // Mostrar alertas detalladas
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: message || 'Archivos descargados exitosamente.',
                    message_list: [
                        ...messageList.map((file: string) => `Archivo descargado: ${file}`),
                        ...failedList.map((file: string) => `${file}`),
                    ],
                });
            })
            .catch((error) => {
                console.error('Error al intentar descargar el archivo ZIP:', error.message);
    
                if (changeAnimation) changeAnimation('error', error.message, true);
                if (hideLoading) hideLoading();
    
                // Mostrar mensaje de error
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: error.message || 'Error al intentar descargar el archivo ZIP.',
                });
            });
    };

    const storeWorkerDocument = (workerDocument: WorkerDocument, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.WORKER_DOCUMENTS.STORE_WORKER_DOCUMENT,
            requestType: 'multipart',
            body: workerDocument,
            setFetching: setFetchingStoreWorkerDocument
        });
    };

    const getWorkerDocumentHistoryByWorkerAndDocumentType = (
        worker_id: number,
        document_id: number,
        events: ServiceEvents = {}
    ) => {
        const url = `${EndPoints.WORKER_DOCUMENTS.HISTORY_BY_WORKER_AND_TYPE}`;
        doGet({
            ...events,
            url: url
                .replaceAll(':worker_id', worker_id.toString())
                .replaceAll(':document_id', document_id.toString()),
            setFetching: setFetchingGetWorkerDocumentHistoryByWorkerAndDocumentType
        });
    };

    return {
        fetchingGetWorkerDocuments,
        fetchingStoreWorkerDocument,
        fetchingGetWorkerDocumentHistoryByWrokerAndDocumentType,
        getWorkerDocumentsByWorker,
        getWorkerDocumentHistoryByWorkerAndDocumentType,
        getWorkerDocumentBySet,
        storeWorkerDocument
    };
};

export default useWorkerDocumentService;
