import React, { useEffect, useState } from 'react';
import {  QuestionDataForm, LinkInspectionOption } from '../../../../../../app/models/Question';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../../../components/SelectTwo';
import useUserService from '../../../../../../app/services/hooks/useUserService';
import { ServiceResponse } from '../../../../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { UserDataForm } from '../../../../../../app/models/User';
import LazyLoading from '../../../../../../components/LazyLoading';

type Props = {
    input: QuestionDataForm; 
    handleChange: (user:any) => void;
    disabled?: boolean
};

const QuestionUserField = ({ input, handleChange, disabled=false }: Props) => {

    const { allUsers, fetchingGetAllUsers } = useUserService();

    const [users, setUsers] = useState<UserDataForm[]>([]);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = () => {
        allUsers({
            onSuccess: (response: ServiceResponse) => {
                setUsers(response.data.users);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };
    

    return fetchingGetAllUsers ? (
        <LazyLoading height={300} />
    ) : (
        <div className="mb-5">
            <SelectTwo
                name={'user'}
                id={`${input.id}`}
                inputValue={input.text}
                options={SelectTwoMapperOptions(users)}
                onChange={handleChange}
                placeholder={'Seleccione Usuario'}
                isClearable={true}
                disabled={disabled}
            />
        </div>
    );
};

export default QuestionUserField;