import React, { useEffect } from 'react';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Carrier } from '../../../../app/models/Carrier';
import { Provider } from '../../../../app/models/Provider';
import { Truck } from '../../../../app/models/Truck';
import { Hopper } from '../../../../app/models/Hopper';
import { DailyCarrier } from '../../../../app/models/DailyCarrier';
import { DispatchProgramItemWithTransportOrder } from '../../../../app/models/DispatchProgramItem';
import { TransportOrder } from '../../../../app/models/TransportOrder';

interface DispatchProgramItemProps {
    dispatchProgramWithTransportOrderDataForm: DispatchProgramItemWithTransportOrder;
    setDispatchProgramWithTransportOrderDataForm: (
        dispatchProgramWithTransportOrderDataForm: DispatchProgramItemWithTransportOrder
    ) => void;
    transportOrders: TransportOrder[];
    dailyCarrier: DailyCarrier;
    carriers: Carrier[];
    providers: Provider[];
    trucks: Truck[];
    hoppers: Hopper[];
    setDailyCarrier: (dailyCarrier: DailyCarrier) => void;
    errorFields?: any;
}

const DispatchProgramItemForm = ({
    dispatchProgramWithTransportOrderDataForm,
    setDispatchProgramWithTransportOrderDataForm,
    transportOrders,
    dailyCarrier,
    setDailyCarrier,
    carriers,
    providers,
    trucks,
    hoppers,
    errorFields
}: DispatchProgramItemProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {

        const obj = { [name]: value };
       
        if(name === 'carrier_id'){

            const carrier = carriers.find(c => c.id === value)

            if(carrier){

                obj.provider_id = carrier.provider_id ?? 0;
            }
        }

        setDailyCarrier({ ...dailyCarrier, ...obj });

    };
    const handleCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setDispatchProgramWithTransportOrderDataForm({
            ...dispatchProgramWithTransportOrderDataForm,
            is_internal: !dispatchProgramWithTransportOrderDataForm.is_internal
        });
    };

    const handleChangeSelectTwoTransportOrder = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        setDispatchProgramWithTransportOrderDataForm({
            ...dispatchProgramWithTransportOrderDataForm,
            ...obj
        });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="internal"
                            value="internal"
                            checked={dispatchProgramWithTransportOrderDataForm.is_internal}
                            id="it_internal"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="it_internal">
                            Propio
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="external"
                            value="external"
                            checked={!dispatchProgramWithTransportOrderDataForm.is_internal}
                            id="it_external"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="it_external">
                            Tercero
                        </label>
                    </div>
                </div>
            </div>
            {dispatchProgramWithTransportOrderDataForm.is_internal && (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <SelectTwo
                            name="transport_order_id"
                            id="transport_order_id"
                            inputValue={
                                dispatchProgramWithTransportOrderDataForm.transport_order_id
                            }
                            options={SelectTwoMapperOptions(transportOrders)}
                            hasError={fieldHasError('transport_order_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwoTransportOrder(
                                    'transport_order_id',
                                    value?.value
                                )
                            }
                            placeholder={'Seleccione orden transporte'}
                            onFocus={() => onFocusRemove('transport_order_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('transport_order_id')}
                        </div>
                    </div>
                </div>
            )}
            <h5 className="mb-3">Datos transporte</h5>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Conductor</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="carrier_id"
                        id="carrier_id"
                        inputValue={dailyCarrier.carrier_id}
                        options={SelectTwoMapperOptions(carriers, 'id', ['name'])}
                        hasError={fieldHasError('carrier_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('carrier_id', value.value)}
                        placeholder={'Seleccione conductor'}
                        onFocus={() => onFocusRemove('carrier_id')}
                        disabled={dispatchProgramWithTransportOrderDataForm.is_internal}
                        isClearable={false}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('carrier_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tracto</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="truck_id"
                        id="truck_id"
                        inputValue={dailyCarrier.truck_id}
                        options={SelectTwoMapperOptions(trucks)}
                        hasError={fieldHasError('truck_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('truck_id', value.value)}
                        placeholder={'Seleccione tracto'}
                        onFocus={() => onFocusRemove('truck_id')}
                        disabled={dispatchProgramWithTransportOrderDataForm.is_internal}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('truck_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tolva</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="hopper_id"
                        id="hopper_id"
                        inputValue={dailyCarrier.hopper_id}
                        options={SelectTwoMapperOptions(hoppers)}
                        hasError={fieldHasError('hopper_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('hopper_id', value.value)}
                        placeholder={'Seleccione tolva'}
                        onFocus={() => onFocusRemove('hopper_id')}
                        disabled={dispatchProgramWithTransportOrderDataForm.is_internal}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('hopper_id')}
                    </div>
                </div>
            </div>
            {
                !dispatchProgramWithTransportOrderDataForm.is_internal  &&
                <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Proveedor</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="provider_id"
                        id="provider_id"
                        inputValue={dailyCarrier.provider_id}
                        options={SelectTwoMapperOptions(providers)}
                        hasError={fieldHasError('provider_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('provider_id', value.value)}
                        placeholder={'Seleccione proveedor'}
                        onFocus={() => onFocusRemove('provider_id')}
                        disabled={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                </div>
            </div>
                
            }
            

            <br />
        </>
    );
};

export default DispatchProgramItemForm;
