import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { defaultSectionDataForm, SectionDataForm, SectionFilters } from '../../../app/models/Section';
import useSectionService from '../../../app/services/hooks/useSectionService';
import SectionForm from './forms/SectionForm';


interface Props {
    sectionId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SectionEdit = ({ sectionId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditSection,
        editSection,
        fetchingUpdateSection,
        updateSection,
    } = useSectionService();

   
    const [section, setSection] = useState<SectionDataForm>(defaultSectionDataForm);
    const [errorFields, setErrorFields] = useState<any>();
 
    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando sección...');
        editSection(sectionId, {
            onSuccess: (response: ServiceResponse) => {
                
                setSection({
                    ...response.data.section,
                    next_section: response.data.section.next_section_id ? true : false
                });
           
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };


    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando sección...');
        updateSection(sectionId ,section, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });

                    if (onSaved) onSaved();
                 
                },
                onError: (response: ServiceResponse) => {
                    
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                 
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            }
        );
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditSection ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <SectionForm
                        sectionForm={section}
                        setSectionForm={setSection}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateSection}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateSection} />
                </div>
            </div>
        </>
    );
};

export default SectionEdit;
