import React from 'react'
import DefaultDataTable from '../../../../components/default/DefaultDataTable'
import { UserResponseDataForm, UserResponseFilters } from '../../../../app/models/UserResponse';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import UserResponseFilter from './UserResponseFilter';
import { momentParseDate } from '../../../../helpers';
import UserResponseSwitchChange from '../UserResponseSwitchChange';

interface Props {
    userResponses: UserResponseDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    showResponses?: (id: number) => void;
    filter: UserResponseFilters;
    setFilter: (filter: UserResponseFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const UserResponseDataTable = (
    {
        userResponses,
        totalRows,
        loading = false,
        edit,
        destroy,
        show,
        showResponses,
        filter,
        setFilter,
        callbackSuccess,
        onSearch
    }: Props
) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: UserResponseDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Usuario',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'user',
            cell: (row: UserResponseDataForm, index: any, column: any) => (
                <span className="">{row.user?.name}</span>
            )
        },
        {
            name: 'Fecha de Completado',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'completed_at',
            cell: (row: UserResponseDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row.completed_at)}</span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row.state,
            sortable: true,
            sortField: 'state',
            cell: (row: UserResponseDataForm, index: any, column: any) => (
                <span className="">{
                    row.state === "IN_PROGRESS" 
                        ? 'En Progreso' 
                        : row.state === "COMPLETED" 
                            ?  'Completado' 
                            : null
                }</span>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row?.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: UserResponseDataForm, index: any, column: any) => (
                <UserResponseSwitchChange
                    checkValue={row.is_active ?? true}
                    userResponseId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: UserResponseDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];
    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <UserResponseFilter 
                        filter={filter} 
                        setFilter={setFilter} 
                        onSearch={onSearch}
                    />
                </div>
            </div>
            <DefaultDataTable
                columns={columns}
                data={userResponses}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    )
}

export default UserResponseDataTable;
