export enum Roles {
    ADMIN = 'administrador',
    MANAGERS = 'gerentes',
    RUNNER = 'runner',
    OPERATIONS_MANAGER = 'jefe_operaciones'
}

export enum OptionTrip {
    ONE_WAY = 'ONE_WAY',
    ROUND_TRIP = 'ROUND_TRIP'
}

export enum InputResponseType {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    SELECT = 'SELECT',
    CHECKBOX = 'CHECKBOX',
    SWITCH = 'SWITCH',
    MULTIPLESELECT = 'MULTIPLESELECT',
    RADIOBUTTON = 'RADIOBUTTON',
    TEXTAREA = 'TEXTAREA',
    FILE = 'FILE',
    DATE = 'DATE',
    TIME = 'TIME',
    DATETIME = 'DATETIME',
    USER = 'USER'
}
