import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { SubMenu } from 'react-pro-sidebar';
import { verify_path } from '../utils/getPermissions';

interface Props {
  children: JSX.Element[] | JSX.Element;
  pathList: string[];
  pathName: string;
  label: string;
  collapsed: boolean;
  icon?: ReactElement | null;
  showingMainMenu?: boolean;
}

const SubMenuComponent = ({
  children,
  pathList,
  pathName,
  label,
  collapsed,
  showingMainMenu,
  icon = null,
}: Props) => {
  const [selectedMenu, setSelectedMenu] = useState<boolean | undefined>(
    verify_path(pathList, pathName)
  );

  const submenuRef = useRef<any>();

  /**
   * Helper function to recursively calculate all paths
   * for the current menu and its children
   */
  const getAllPaths = (children: JSX.Element[] | JSX.Element): string[] => {
    let paths: string[] = [...pathList]; // Include current pathList
    React.Children.forEach(children, (child: any) => {
      if (child?.props?.pathList) {
        paths = paths.concat(child.props.pathList); // Add paths from children
      }
    });
    return paths;
  };

  useEffect(() => {
    const submenuNode = submenuRef.current;
    const submenuContent = submenuNode.querySelector('.ps-submenu-content');

    // Calculate the complete pathList including children
    const allPaths = getAllPaths(children);

    // console.log('SubMenuComponent Debug:', {
    //   allPaths,
    //   pathName,
    //   selectedMenu,
    // });

    if (verify_path(allPaths, pathName)) {
      setSelectedMenu(true);
      submenuContent.style.height = 'auto';
      submenuContent.style.display = 'block';
    } else {
      setSelectedMenu(false);
      submenuContent.style.height = '0';
      submenuContent.style.display = 'none';
    }
    submenuContent.style.overflow = 'hidden';
  }, [pathName]);

  useEffect(() => {
    const submenuNode = submenuRef.current;
    const submenuContent = submenuNode.querySelector('.ps-submenu-content');

    if (collapsed && selectedMenu && verify_path(pathList, pathName)) {
      submenuContent.removeAttribute('style');
    } else if (collapsed && !verify_path(pathList, pathName) && selectedMenu) {
      submenuContent.style.height = '0';
      submenuContent.style.display = 'none';
      setSelectedMenu(false);
    } else if (!collapsed && verify_path(pathList, pathName)) {
      setSelectedMenu(true);
      submenuContent.style.height = 'auto';
      submenuContent.style.display = 'block';
    } else if (!collapsed && !verify_path(pathList, pathName)) {
      submenuContent.style.height = '0';
      submenuContent.style.display = 'none';
      setSelectedMenu(false);
    }
    submenuContent.style.overflow = 'hidden';
  }, [collapsed]);

  useEffect(() => {
    const submenuNode = submenuRef.current;
    const submenuContent = submenuNode.querySelector('.ps-submenu-content');

    if (!showingMainMenu && verify_path(pathList, pathName) && selectedMenu) {
      submenuContent.removeAttribute('style');
    } else if (!showingMainMenu && !verify_path(pathList, pathName) && selectedMenu) {
      submenuContent.style.height = '0';
      submenuContent.style.display = 'none';
      setSelectedMenu(false);
    } else if (showingMainMenu && verify_path(pathList, pathName)) {
      setSelectedMenu(true);
      submenuContent.style.height = 'auto';
      submenuContent.style.display = 'block';
    } else if (showingMainMenu && !verify_path(pathList, pathName)) {
      submenuContent.style.height = '0';
      submenuContent.style.display = 'none';
      setSelectedMenu(false);
    }
    submenuContent.style.overflow = 'hidden';
  }, [showingMainMenu]);

  const handleSubMenuToggle = () => {
    setSelectedMenu(!selectedMenu);
  };

  return (
    <SubMenu
      ref={submenuRef}
      defaultOpen={selectedMenu}
      open={selectedMenu}
      onClick={handleSubMenuToggle}
      label={label}
      icon={icon}
    >
      {children}
    </SubMenu>
  );
};

export default SubMenuComponent;
