import React, { useEffect, useState } from 'react'
import DefaultCard from '../../../../components/default/DefaultCard'
import DefaultModal from '../../../../components/default/DefaultModal';
import useSurveyService from '../../../../app/services/hooks/useSurveyService';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { SurveyDataForm, SurveyFilters } from '../../../../app/models/Survey';
import SurveyDataTable from '../../../Surveys/components/tables/SurveyDataTable';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import useNavigationPage from '../../../../hooks/useNavigationPage';
import useUserResponseService from '../../../../app/services/hooks/useUserResponseService';


interface Props {
    eventTypeId?: number;
    calendarDate: string | Date;
}

interface ButtonProps {
    confirmButton?: any;
    cancelButton?: any;
    updateButton?: any;
}

const CalendarChecklistContainer = ({ eventTypeId, calendarDate }: Props) => {

    const { getSurveyByEventTypeId, fetchingGetSurveyByEventType } = useSurveyService();
    const { getInProgressUserResponsesBySurvey, fetchingGetInProgressUserResponsesBySurvey } = useUserResponseService();
    const { navigationPage } = useNavigationPage();

    const [surveys, setSurveys] = useState<SurveyDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [filter, setFilter] = useState<SurveyFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
    });

    useEffect(()=>{
        reloadTable()
    },[filter.page, filter.per_page, filter.sort, filter.order, eventTypeId])

    const reloadTable = () => {
        if(eventTypeId){
            getSurveyByEventTypeId(eventTypeId, filter, {
                onSuccess: (response: ServiceResponse) => {
                    setSurveys(response.data.surveys);
                    setTotalRows(response.data.total_rows);
                }
            });
        }
    }
    
    const respondSurvey = (surveyId: number) => {
        let _text = 'Está a punto de responder la encuesta #' + surveyId;
        
        let buttons: ButtonProps = {
            confirmButton: {
                label: 'Si, confirmar',
                onClick: () => {
                    navigationPage('/surveys/' + surveyId + '/respond?date=' + calendarDate.toString());
                }
            },
            cancelButton: {
                label: 'Cancelar',
                onClick: () => {
                    setTimeout(() => {
                        useReactConfirmAlert().infoAlert({
                            title: 'Cancelado',
                            message: 'La encuesta no se ha contestado.'
                        });
                    }, 0);
                }
            }
        }

        getInProgressUserResponsesBySurvey(surveyId, calendarDate.toString(),{
            onSuccess: (response: ServiceResponse) => {
                if(response.data.user_responses.length > 0){
                    const userResponseId = response.data.user_responses[0].id
                    _text = 'Existe una encuesta en progreso para esta fecha, desea continuarla'
                    buttons = {
                        ...buttons,
                        confirmButton: {
                            label: 'Sí, Continuar Encuesta en Progreso',
                            onClick: () => {
                                navigationPage('/surveys/' + userResponseId + '/respond?user_response=true&date=' + calendarDate.toString());
                            }
                        },
                        updateButton: {
                            label: 'No, Comenzar Nueva',
                            onClick: () => {
                                navigationPage('/surveys/' + surveyId + '/respond?date=' + calendarDate.toString());
                            }
                        }
                    }
                }
                const requestConfirmationProps = {
                    title: 'Responder Encuesta',
                    message: _text,
                    buttons: buttons
                };

                buttons.updateButton 
                    ? useReactConfirmAlert().requestConfirmationMulti(requestConfirmationProps)
                    : useReactConfirmAlert().requestConfirmation(requestConfirmationProps);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });


    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <h5>Encuestas</h5>
                    <div className="col-12">
                        <SurveyDataTable
                            surveys={surveys}
                            totalRows={totalRows}
                            loading={fetchingGetSurveyByEventType}
                            hideActive={true}
                            edit={respondSurvey}
                            // showResponses={showResponses}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
        </>
    )
}

export default CalendarChecklistContainer
