import React, { useEffect, useRef, useState } from 'react'
import { QuestionDataForm } from '../../../../../../app/models/Question';
import useHandleErrorFields from '../../../../../../hooks/useHandleErrorFields';
import FileCarrousel from '../../../FileCarrousel';

type Props = {
    input: QuestionDataForm; 
    setQuestionForm: (question: QuestionDataForm) => void;
    disabled?: boolean;
    errorFields?: any;
};

const QuestionImageField = ({ input, setQuestionForm, disabled, errorFields }: Props) => {
    
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const inputRef = useRef<HTMLInputElement>(null);
    const [imageURLs, setImageURLs] = useState<string[]>([]); 

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        if (!input.selectedImages) {
            setImageURLs([]);
        }
    }, [input]);

    useEffect(() => {
        if (input.selectedImages) {
            const urls = input.selectedImages.map((file) => URL.createObjectURL(file));
            setImageURLs(urls);
            setQuestionForm(
                { 
                    ...input, 
                    images: urls
                }
            );
        }
    }, [input.selectedImages]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!event.target.files) return;

        const newFiles = Array.from(event.target.files);
        const updatedImages = [
            ...(input.selectedImages || []),
            ...newFiles,
        ];

        setQuestionForm(
            { 
                ...input, 
                selectedImages: updatedImages
            }
        );

        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const handleClick = () => {
        
        if (inputRef.current) {
            inputRef.current?.click();
        }
    };

    const eraseImage = (currentImageIndex: number) => {
        if (imageURLs.length === 0) return;

        const updatedImageURLs = imageURLs.filter((_, index) => index !== currentImageIndex - 1);
        setImageURLs(updatedImageURLs);

        if (input.selectedImages){
            const updatedSelectedImages = input.selectedImages.filter((_, index) => index !== currentImageIndex - 1);
            setQuestionForm({
                ...input,
                selectedImages: updatedSelectedImages
            });
        } 
    };

    return (
        <div className="mb-2 row">
            <div className="col-md-12">
                <label className="col-form-label">Agregar Imagen</label>

                <input
                    className={`form-control ${fieldHasError('title')}`}
                    style={{ display: 'none' }}
                    type="file"
                    name="images"
                    id="images"
                    accept={'image/*'}
                    multiple
                    onChange={handleChange}
                    ref={inputRef}
                    placeholder="Ingrese pregunta a realizar"
                    onFocus={() => onFocusRemove('question_text')}
                />
                <br />  
                <div className={'input-group mb-3'}>
                    <button
                        style={{ zIndex: 0 }}
                        onClick={handleClick}
                        className={`form-control btn btn-outline-secondary`}
                        disabled={disabled}
                    >
                        Cargar Archivo
                    </button>
                </div>

                <FileCarrousel
                    imageURLs={imageURLs}
                    eraseImage={eraseImage}
                />
                
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('question_text')}
                </div>
            </div>
        </div>
    )
}

export default QuestionImageField
