import React, { useEffect, useRef, useState } from 'react'
import { QuestionDataForm } from '../../../../../app/models/Question';
import useHandleErrorFields from '../../../../../hooks/useHandleErrorFields';


type Props = {
    questionForm: QuestionDataForm;
    setQuestionForm?: (question: QuestionDataForm) => void;
    errorFields?: any;
};

const FormFile = ({ questionForm, setQuestionForm, errorFields }: Props) => {
    
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const inputRef = useRef<HTMLInputElement>(null);
    const [imageURLs, setImageURLs] = useState<string[]>([]); 
    const [currentImageIndex, setCurrentImageIndex] = useState(1);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        if (questionForm.selectedImages) {
            const urls = questionForm.selectedImages.map((file) => URL.createObjectURL(file));
            setImageURLs(urls);
        }
    }, [questionForm.selectedImages]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {

        if (!event.target.files) return;

        const { name, value, files } = event.target;
        
        const newFiles = Array.from(event.target.files);
        const updatedImages = [
            ...(questionForm.selectedImages || []),
            ...newFiles,
        ];
    };

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current?.click();
        }
    };

    return (
        <div className="mb-2 row">
            <div className="col-md-12">
                <label className="col-form-label">Agregar Imagen</label>

                <input
                    className={`form-control ${fieldHasError('title')}`}
                    style={{ display: 'none' }}
                    type="file"
                    name="images"
                    id="images"
                    accept={'image/*'}
                    multiple
                    onChange={handleChange}
                    ref={inputRef}
                    placeholder="Ingrese pregunta a realizar"
                    onFocus={() => onFocusRemove('question_text')}
                />
                <br />  
                <div className={'input-group mb-3'}>
                    <button
                        style={{ zIndex: 0 }}
                        onClick={handleClick}
                        className={`form-control btn btn-outline-secondary`}
                        disabled={true}
                    >
                        Cargar Archivo
                    </button>
                </div>
                
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('question_text')}
                </div>
            </div>
        </div>
    )
}

export default FormFile
