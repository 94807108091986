import { TruckDataForm, TruckFilters } from '../../../../app/models/Truck';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ActiveControl from '../../../../components/ActiveControl';
import { HopperDataForm } from '../../../../app/models/Hopper';

import { momentParseDate } from '../../../../helpers';

import { formatNumber } from '../../../../utils/utils';
import { ExpenseDataForm, ExpenseFilters } from '../../../../app/models/Expense';
import CalendarExpenseFilter from './CalendarExpenseFilter';
import { Link } from 'react-router-dom';

import circleRed from '../../../../assets/images/icons/circle-color-red.svg';
import circleGreen from '../../../../assets/images/icons/circle-color-green.svg';
import circleGrey from '../../../../assets/images/icons/circle-color-grey.svg';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useContext } from 'react';

interface Props {
    expenses: ExpenseDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    approved?: (id: number, value: boolean, message: string) => void;
    cancel?: (id: number, value: boolean) => void;
    filter: ExpenseFilters;
    setFilter: (filter: ExpenseFilters) => void;
    expensePage?: boolean;
    paginationRowsPerPageOptions?: any[];
    documentExpense: (id: number) => void;
    showExpenseHistory?: (expenseId: number) => void;
    onSearch: () => void
}

const CalendarExpenseDataTable = ({
    expenses,
    totalRows,
    loading = false,
    edit,
    destroy,
    approved,
    cancel,
    show,
    filter,
    setFilter,
    expensePage = false,
    documentExpense,
    showExpenseHistory,
    onSearch
}: Props) => {

    const { auth } = useContext(AuthContext);

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span className="">
                    {approved && (
                        <img
                            src={
                                row.is_approved == null
                                    ? circleGrey
                                    : !row.is_approved
                                    ? circleRed
                                    : circleGreen
                            }
                            alt="circle"
                            style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '5px',
                                marginBottom: '3px'
                            }}
                        />
                    )}
                    <span className="">{row.id}</span>
                </span>
            )
        },
        {
            name: 'Orden de transporte',
            selector: (row: any) => row?.transport_order_id,
            sortable: true,
            sortField: 'transport_order_id',
            omit: !expensePage,
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span className="">
                    {row.transport_order_id ? (
                        expensePage ? (
                            <Link to={`/transport-orders/${row.transport_order_id}/edit`}>
                                #{row.transport_order_id}
                            </Link>
                        ) : (
                            row.transport_order_id
                        )
                    ) : (
                        ''
                    )}
                </span>
            )
        },
        {
            name: 'Tipo de gasto',
            selector: (row: any) => row?.expense_type,
            sortable: true,
            sortField: 'expense_type',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span className="">{row.expense_type}</span>
            )
        },

        {
            name: 'Valor',
            selector: (row: any) => row?.amount,
            sortable: true,
            sortField: 'amount',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {formatNumber(row.amount ? Number(row.amount) : 0)} {row.currency?.name}
                    </span>
                </>
            )
        },
        {
            name: 'Proveedor',
            selector: (row: any) => row?.provider,
            sortable: true,
            sortField: 'provider',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.provider ? row.provider : '' }}
                ></span>
            )
        },
        {
            name: 'Fecha de emisión',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'issue_date',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name: 'Tipo de documento',
            selector: (row: any) => row?.expense_document_type,
            sortable: true,
            sortField: 'expense_document_type',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span className="">{row?.expense_document_type}</span>
            )
        },

        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <div className="">
                    {documentExpense && (
                        <ButtonTableAction
                            callbackFunction={() => documentExpense(row?.id ?? -1)}
                            classIcon={'mdi mdi-download'}
                            colorIcon={row.photo ? 'text-success' : 'text-secondary'}
                            locked={!row.photo}
                            errorMessage={'No se puede descargar este documento.'}
                            title={'Descargar documento'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                    {approved &&
                        (row.is_approved === false || row.is_approved === null) &&
                        auth &&
                        auth?.roles &&
                        !auth?.roles.includes('Conductor') && (
                            <>
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, true, 'aprobado')
                                    }
                                    classIcon={'mdi mdi-checkbox-marked-circle'}
                                    colorIcon={'text-info'}
                                    errorMessage={'No se puede aprobar este registro.'}
                                    title={'Aprobar'}
                                />
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, false, 'rechazado')
                                    }
                                    classIcon={'mdi mdi-close-circle'}
                                    colorIcon={'text-danger'}
                                    errorMessage={'No se puede rechazar este registro.'}
                                    title={'Rechazar'}
                                />
                            </>
                        )}
                    {cancel && (row.is_approved === false || row.is_approved === true) && (
                        <ButtonTableAction
                            callbackFunction={() => cancel(row?.id ?? -1, row.is_approved ?? false)}
                            classIcon={'fa fa-ban'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede anular este registro.'}
                            title={'Anular'}
                        />
                    )}
                    {showExpenseHistory &&
                        auth &&
                        auth?.roles &&
                        !auth?.roles.includes('Conductor') && (
                            <ButtonTableAction
                                callbackFunction={() => showExpenseHistory(row?.id ?? -1)}
                                classIcon={'mdi mdi-history'}
                                colorIcon={'text-info'}
                                errorMessage={'No se puede mostrar el historial de este registro.'}
                                title={'Mostrar historial'}
                            />
                        )}
                    
                    
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <CalendarExpenseFilter
                        filter={filter}
                        setFilter={setFilter}
                        showTransportOrder={expensePage}
                        onSearch={onSearch}
                    />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={expenses}
                progressPending={loading}
                isMobileStacked={true}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
                // conditionalRowStyles={conditionalRowStyles}
            />
        </>
    );
};

export default CalendarExpenseDataTable;
